/* You can add global styles to this file, and also import other style files */
:root {
    --grey: #a1a3a6;
    --blue: #1d3552;
    --lightblue: #56bee9;
}
html, body {
    margin:0;
    padding:0;
}
body {
    font-family: "Open Sans", Arial, sans-serif;
    font-size:16px;
}
.section {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
}
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    max-width:1140px;
    padding:0 20px;
    box-sizing: border-box;;
}
.header-top {
    background-color: var(--grey);
    padding:10px 0;
    font-size:14px;
}
.header-top a {
    color:white;
    text-decoration:none;
}
.header-top a:hover {
    text-decoration: underline;
}
.header-top .contact-info {
    display:flex;
    gap:20px;
}
.contact-info svg {
    width:20px;
    fill:white;
    display:none;
}
.header-links {
    display:flex;
    gap:20px;
    justify-content: space-between;
    align-items: center;
}
.header-links .linkedin {
    background-color:white;
    width:24px;
    height:24px;
    border-radius:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.header-links .linkedin svg {
    width:12px;
    height:12px;
    display:block;
    position:relative;
}
.header-logo {
    padding:20px 0px;
}
.header-logo img {
    width: 225px;
}
.product-links {
    background-color:var(--blue);
    padding:30px 0;
}
.product-links .flex-container {
    flex-direction:column;
    gap:20px;
}
.product-links h1 {
    color:white;
    font-size:18px;
    text-transform: uppercase;
    letter-spacing:5px;
    font-weight:600;
    margin:0;
}
.products-wrapper {
    display:grid;
    grid-template-columns:repeat(5, 1fr);
    gap:20px;
    width:100%;
}
.products-wrapper .product-link {
    border: 1px solid rgba(255, 255, 255, 0.15);
    width:100%;
    aspect-ratio:1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    box-sizing: border-box;
}
.product-link.active, .product-link:hover {
    border-color:var(--lightblue);
}
.product-link a {
    display:block;
}
.product-link img {
    height:40px;
    width:auto;
    max-width: 100%;
    object-fit: contain;
}

.login-section {
    min-height:430px;
    padding-top: 1em;
    padding-bottom: 1em;
    background:url("/assets/img/bodynet-bms-login.jpg") no-repeat center center;
    background-size:cover;
}
.login-container {
    background-color:white;
    padding:30px 80px;
    display:flex;
    gap:30px;
    flex-direction:column;
    min-width:450px;
    box-sizing:border-box;
}
.login-logo {
    width:auto;
    height:50px;
    object-fit:contain;
    max-width:100%;
}

.login-form {
    display:flex;
    flex-direction:column;
    gap:20px;
}
.login-form .form-item {
    display:flex;
    flex-direction:column;
    gap:10px;
    border-bottom: 1px solid rgba(9, 53, 82, 0.15);
    padding-bottom:5px;
    position:relative;
}
.login-form label {
    font-size:0.8em;
    font-weight:500;
}
.forgot-password {
    position:absolute;
    top:0;
    right:0;
    font-size:0.6em;
    color:var(--grey);
    text-decoration: none;
}
.forgot-password:hover {
    text-decoration: underline;
}

.login-button {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
form input[type="text"], form input[type="password"] {
    border:none;
    padding-bottom:5px;
    outline:none;
    padding-left:30px;
}
.cost-centre input {
    background:url("/assets/img/cog.png") no-repeat 5px 1px;
    background-size:15px;
}
.username input {
    background:url("/assets/img/user.png") no-repeat 5px 1px;
    background-size:15px;
}
.password input {
    background:url("/assets/img/lock.png") no-repeat 5px 1px;
    background-size:12px;
}


.page-bodynet-bms {
    background-image:url("/assets/img/bodynet-bms-login.jpg");
}
.page-autopad {
    background-image:url("/assets/img/autopad-login.jpg");
}
.page-shopfloor-mobile {
    background-image:url("/assets/img/shopfloor-login.jpg");
}
.page-shopfloor {
    background-image:url("/assets/img/shopfloor-login.jpg");
}
.page-connect {
    background-image:url("/assets/img/connect-login.jpg");
}

.footer {
    padding:50px 0;
}
.footer-content .flex-container {
    flex-direction:column;
    gap:20px;
    align-items: start;
    background:url("/assets/img/dots.png") no-repeat center right;
    background-size:contain;
}
.company-info {
    display:flex;
    gap:40px;
    color:var(--blue);
}
.company-info strong {
    color:var(--lightblue);
}
.footer-bottom {
    font-size: 0.8em;
}
.footer img {
    width:190px;
}
@media only screen and (max-width:1024px) {
    .login-container {
        padding:20px 40px;
    }
}
@media only screen and (max-width:767px) {
    .contact-info svg {
        display:block;
    }
    .contact-info span {
        display:none;
    }
    .header-logo {
        padding:20px 0;
    }
    .header-logo img {
        width: 150px;
    }
    .products-wrapper {
        grid-template-columns:1fr;
    }
    .products-wrapper .product-link {
        aspect-ratio:unset;
        height:50px;
    }
    .login-section {
        height:auto;
        padding:20px 0;
    }
}